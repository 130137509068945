<template>
  <div class="venn-diagram-container">
    <highcharts
      :options="chartOptions"
      class="d-flex justify-center"
    />
  </div>
</template>

<script>
// Import the Highcharts library
import Hightcharts from "highcharts"
import { Chart } from 'highcharts-vue'
import vennInit from "highcharts/modules/venn"

// Initialize the Venn module
vennInit(Hightcharts)

// Export the SFC
export default {
  // Name of the component
  name: 'VennDiagram',

  // Register the Highcharts component
  components: {
    Highcharts: Chart,
  },

  // Accept incoming props
  props: {
    influencers: Array
  },

  // Define computable variables
  computed: {
    /**
     * The chart options
     *
     * @returns {Object}
     */
    chartOptions() {
      return {
        series: [this.series],
        title: null,
        chart: {
          width: 300,
          height: 262,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          formatter() {
            return `<strong>${this.point.sets.join(",")}</strong><br /><span>Followers: ${nFormatter(Math.round(this.point.value))}</span>`;
          },
        },
        legend: {
          align: "left",
        },
        // colors: [],
      };
    },

    /**
     * The chart series data
     *
     * @returns {Object}
     */
    series() {
      // Initialize the items
      const IDs = []
      const items = []

      // Loop through each influencer
      for (const row of this.influencers) {
        // Go through each of them again
        for (const column of this.influencers) {
          // Get the rowId
          const rowId = row.preview.username || row.preview.user_id
          // Get the columnId
          const columnId = column.preview.username || column.preview.user_id

          // If they are the same
          if (rowId === columnId) {
            IDs.push(rowId)

            // Push the data
            items.push({
              sets: [rowId],
              value: row.data.followers,
            })

            // Skip
            continue
          }

          // Compute together ID
          const togetherId = row.data.followers < column.data.followers ? `${rowId}-${columnId}` : `${columnId}-${rowId}`

          // If it already exists
          if (IDs.includes(togetherId)) {
            // Skip
            continue
          }

          IDs.push(togetherId)

          // Push the data
          items.push({
            sets: [rowId, columnId],
            value: row.data.followers * row.data.overlapping_percentage,
          })
        }
      }

      // Return the data
      return {
        type: "venn",
        data: items,
      };
    },
  }
}
</script>
